<template>
  <form class="form-with-input" @submit.stop.prevent="submit">
    <Input v-model="inputs.compoundClass" :placeholder="placeholder" />

    <Btn type="primary" :isSubmit="true" class="form-with-input__btn-done" padding="none">
      <IconMaterial title="done" />
    </Btn>
  </form>
</template>

<script>
  import Btn from '@/uikitBase/btns/Btn';
  import IconMaterial from '@/uikitBase/icons/IconMaterial';
  import Input from '@/uikitBase/inputs/Input';

  const EVENT_ADD = 'add';

  export default {
    name: 'FormWithInput',

    components: { Input, IconMaterial, Btn },

    props: {
      placeholder: {
        type: String,
        required: true,
      },
    },

    data: () => ({
      inputs: {
        compoundClass: '',
      },
    }),

    methods: {
      submit() {
        if (this.inputs.compoundClass) {
          this.$emit(EVENT_ADD, { name: this.inputs.compoundClass });
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .form-with-input {
    display: flex;
    align-items: center;

    &__btn-done {
      flex: none;
      min-width: 32px;
      margin-left: 5px;
    }
  }
</style>
