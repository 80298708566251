<template>
  <div class="item">
    <div class="item__title">
      {{ name }}
    </div>

    <div class="item__actions">
      <BtnIcon iconMaterial="delete" class="item__btn-remove" @click="remove" />
    </div>
  </div>
</template>

<script>
  import BtnIcon from '@/uikitBase/btns/BtnIcon';

  const EVENT_REMOVE = 'remove';

  export default {
    name: 'Item',

    components: { BtnIcon },

    props: {
      name: {
        type: String,
        required: true,
      },
    },

    methods: {
      remove() {
        this.$emit(EVENT_REMOVE);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .item {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__title {
      flex: 1;
      display: flex;
      align-items: center;

      @media (max-width: $screen-xs-max) {
        flex-direction: column;
        align-items: flex-start;
      }
    }

    &__actions {
      display: flex;
      align-items: center;
    }

    &__btn-remove {
      color: $color-text-danger;
      margin-left: 10px;
    }
  }
</style>
