import { makeGraphqlRequestCatalog } from 'api/graphql/graphQLClient';
import { AddApplication } from '@/graphql/catalog/applications/mutations/AddApplication.graphql';
import { ApplicationParts } from '@/graphql/catalog/applications/queries/ApplicationParts.graphql';

export const addApplication = (application) => {
  return makeGraphqlRequestCatalog(AddApplication, application);
};

export const getApplicationParts = () => {
  return makeGraphqlRequestCatalog(ApplicationParts);
};
