<template>
  <form class="form-with-select" @submit.stop.prevent="submit">
    <Select
      v-model="itemSelected"
      :items="items"
      :labelProp="labelProp"
      :isTransparent="true"
      :hasSearch="true"
      :placeholderSearch="placeholder"
      :titleBottomSheet="placeholder"
      class="form-with-select__select"
    />

    <Btn type="primary" :isSubmit="true" class="form-with-select__btn-done" padding="none">
      <IconMaterial title="done" />
    </Btn>
  </form>
</template>

<script>
  import Select from '@/uikitBase/selects/vueSelect/Select';
  import Btn from '@/uikitBase/btns/Btn';
  import IconMaterial from '@/uikitBase/icons/IconMaterial';

  const EVENT_ADD = 'add';

  export default {
    name: 'FormWithSelect',

    components: { IconMaterial, Btn, Select },

    props: {
      items: {
        type: Array,
        required: true,
      },
      labelProp: {
        type: String,
        required: true,
      },
      placeholder: {
        type: String,
        required: true,
      },
    },

    data: () => ({
      itemSelected: {},
    }),

    methods: {
      submit() {
        if (this.itemSelected.id) {
          this.$emit(EVENT_ADD, this.itemSelected);
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .form-with-select {
    display: flex;
    align-items: center;

    &__select {
      flex: 1;
    }

    &__btn-done {
      flex: none;
      min-width: 32px;
      margin-left: 5px;
    }
  }
</style>
