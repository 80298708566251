<template>
  <div class="selector-multiple">
    <div v-if="selectedItems.length" class="selector-multiple__list">
      <template v-for="(item, index) of selectedItems">
        <Item
          :key="index"
          :name="item[labelProp]"
          class="selector-multiple__item"
          @remove="removeItem(index)"
        />

        <div :key="'divider-' + index" class="selector-multiple__divider"></div>
      </template>
    </div>

    <Btn v-if="!isShowAddForm" class="selector-multiple__btn-add-item" @click="showAddForm">
      Add {{ entityName }}
    </Btn>

    <template v-if="isShowAddForm">
      <FormWithSelect
        v-if="items"
        :items="itemsWithoutSelected"
        :labelProp="labelProp"
        :placeholder="placeholder"
        @add="addItem"
      />
      <FormWithInput v-else :placeholder="placeholder" @add="addItem" />
    </template>
  </div>
</template>

<script>
  import Btn from '@/uikitBase/btns/Btn';
  import Item from './private/Item';
  import FormWithSelect from '@/uikitProject/selects/vueSelectorMultiple/private/FormWithSelect';
  import FormWithInput from '@/uikitProject/selects/vueSelectorMultiple/private/FormWithInput';

  const EVENT_MODEL = 'update:selectedItems';

  export default {
    name: 'SelectorMultiple',

    components: { FormWithInput, FormWithSelect, Item, Btn },

    model: {
      prop: 'selectedItems',
      event: EVENT_MODEL,
    },

    props: {
      // { name: '' }
      selectedItems: {
        type: Array,
        required: true,
      },
      items: {
        type: Array,
      },
      entityName: {
        type: String,
      },
      labelProp: {
        type: String,
        default: 'name',
      },
      placeholder: {
        type: String,
        required: true,
      },
      isRequired: {
        type: Boolean,
      },
    },

    data() {
      return {
        isShowAddForm: this.isRequired,
      };
    },

    computed: {
      itemsWithoutSelected() {
        return this.items.filter((item) => !this.selectedItems.includes(item));
      },
    },

    methods: {
      showAddForm() {
        this.isShowAddForm = true;
      },
      hideAddForm() {
        this.isShowAddForm = false;
      },

      addItem(item) {
        this.$emit(EVENT_MODEL, [...this.selectedItems, item]);

        this.hideAddForm();
      },
      removeItem(index) {
        this.$emit(
          EVENT_MODEL,
          this.selectedItems.filter((_, _index) => _index !== index),
        );
      },
    },
  };
</script>

<style lang="scss" scoped>
  .selector-multiple {
    &__list {
      padding: 10px 0;
    }

    &__item {
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }

    &__divider {
      height: 1px;
      width: 100%;
      margin: 10px 0;
      background-color: $color-bg-third;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
</style>
