<template>
  <modal name="createApplication" :pivot-y="0" :width="485" @closed="reset">
    <div class="modal-content modal-content--no-padding">
      <div class="modal-header">
        <h4>Create application</h4>
      </div>

      <div class="modal-body modal-body--big-padding create-application">
        <div class="create-application__content">
          <div class="create-application__field">
            <label>Compounds</label>
            <SelectorMultiple
              v-model="inputs.compounds"
              placeholder="Search compound"
              :items="compounds"
              entityName="compound"
              labelProp="name"
              :isRequired="true"
            />
          </div>

          <div class="create-application__field">
            <label>Compound classes</label>
            <SelectorMultiple
              v-model="inputs.compoundClasses"
              placeholder="Search compound class"
              :items="compoundClasses"
              entityName="compound class"
              labelProp="name"
            />
          </div>

          <div class="create-application__field">
            <Checkbox v-model="inputs.hasFlip">Flip</Checkbox>
          </div>

          <div class="create-application__field">
            <label>Columns</label>
            <SelectorMultiple
              v-model="inputs.columns"
              :items="columnsFormatted"
              placeholder="Search column"
              entityName="column"
              labelProp="name"
            />
          </div>

          <div class="create-application__field">
            <label>Solvents</label>
            <SelectorMultiple
              v-model="inputs.solvents"
              :items="solventsFormatted"
              placeholder="Search solvent"
              entityName="solvent"
              labelProp="name"
            />
          </div>

          <div class="create-application__field">
            <label>Standards</label>
            <SelectorMultiple
              v-model="inputs.standards"
              :items="standardsFormatted"
              placeholder="Search standard"
              entityName="standard"
              labelProp="name"
            />
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <div class="modal-content__actions-panel">
          <div class="actions">
            <button
              :disabled="!this.inputs.compounds.length"
              class="button--accent"
              @click="submit"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
  import ModalComponent from 'components/element/ModalComponent.vue';
  import SelectorMultiple from '@/uikitProject/selects/vueSelectorMultiple/SelectorMultiple';
  import { addApplication, getApplicationParts } from 'api/graphql/catalog/applications';
  import Checkbox from '@/uikitBase/switchers/Checkbox';
  import SampleSocket, { SampleSocketEvents } from 'api/sockets/SampleSocket';
  import GraphqlResponseError from '@/errors/GraphqlResponseError';

  const createDefaultInputValues = () => ({
    compounds: [],
    compoundClasses: [],
    columns: [],
    solvents: [],
    standards: [],
    hasFlip: false,
  });

  export default {
    name: 'CreateApplicationModal',

    components: {
      Checkbox,
      SelectorMultiple,
      modal: ModalComponent,
    },

    props: {
      injection: {
        type: Object,
        required: true,
      },
      method: {
        type: Object,
        required: true,
      },
      sampleSocket: {
        type: SampleSocket,
        required: true,
      },
      token: {
        type: String,
      },
      column: {
        type: Object,
        required: true,
      },
    },

    data: () => ({
      inputs: createDefaultInputValues(),

      columns: [],
      solvents: [],
      standards: [],
      compounds: [],
      compoundClasses: [],

      attemptsToGenerateToken: 0,
    }),

    computed: {
      columnsFormatted() {
        return this.columns.map((column) => ({
          id: column.id,
          name: column.product.name,
          partNumber: column.partNumber,
        }));
      },
      solventsFormatted() {
        return this.solvents.map((solvent) => ({ id: solvent.id, name: solvent.product.name }));
      },
      standardsFormatted() {
        return this.standards.map((standard) => ({ id: standard.id, name: standard.product.name }));
      },
    },

    created() {
      this.init();
    },

    methods: {
      async init() {
        const parts = await getApplicationParts();
        this.columns = parts.columns;
        this.solvents = parts.solvents;
        this.standards = parts.standards;
        this.compounds = parts.compounds;
        this.compoundClasses = parts.compoundClasses;

        this.initDefaultColumn();
      },

      initDefaultColumn() {
        // Get part number from the end of the name
        const partNumber = this.column.name.match(/\S+[\d.]+$/)?.[0];

        if (partNumber) {
          const defaultColumn = this.columnsFormatted.find(
            (column) =>
              column.partNumber &&
              column.partNumber.toLowerCase().includes(partNumber?.toLowerCase()),
          );

          defaultColumn && this.inputs.columns.push(defaultColumn);
        }
      },

      async createApplication() {
        try {
          await addApplication({
            compounds: this.inputs.compounds.map((compound) => ({
              compound_id: compound.id,
            })),
            compoundClasses: this.inputs.compoundClasses.map((compoundClass) => ({
              compound_class_id: compoundClass.id,
            })),
            hasFlip: this.inputs.hasFlip,
            method: this.method,
            source: this.injection.id,
            token: this.token,
            columns: this.inputs.columns.map((column) => ({ column_id: column.id })),
            solvents: this.inputs.solvents.map((solvent) => ({ solvent_id: solvent.id })),
            standards: this.inputs.standards.map((standard) => ({ standard_id: standard.id })),
          });

          this.close();
        } catch (e) {
          if (e instanceof GraphqlResponseError) {
            this.notifyError(e.message);
          }
        }
      },

      submit() {
        if (this.token) {
          this.createApplication();
        } else if (this.attemptsToGenerateToken < 3) {
          this.attemptsToGenerateToken++;

          const handleSocketEvent = async () => {
            this.attemptsToGenerateToken = 0;
            this.sampleSocket.removeEventListener(SampleSocketEvents.SAMPLE, handleSocketEvent);

            await this.$nextTick();
            this.submit();
          };

          this.sampleSocket.addEventListener(SampleSocketEvents.SAMPLE, handleSocketEvent);
          this.showNotificationIfRpcError(() => this.sampleSocket.share());
        }
      },

      reset() {
        this.inputs = createDefaultInputValues();
      },
      close() {
        this.$modal.hide('createApplication');
      },
    },
  };
</script>

<style lang="scss" scoped>
  .create-application {
    @media (max-width: $screen-xxs-max) {
      padding: 16px;
    }

    &__field {
      margin-bottom: 20px;
    }
  }
</style>
